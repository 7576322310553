/* Use back ticks ` in favour of single ' or double "" quotes to have strings evaluated literally in
the case of inline quotes, apostrophes and special characters */

/* eslint-disable quotes */
const universal = {
  u: {
    claim: {
      phone: `1-844-929-2637`,
      phoneVanity: `1-844-WAWANESA (929-2637)`,
    },
  },
}

export default universal

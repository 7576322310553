import React, { useContext } from 'react'
import { LangContext } from '../../../localization/localizationContext'

const Pagination = ({ current = 1, pageSize = 10, total, onChange }) => {
  const totalPages = Math.ceil(total / pageSize)
  const { translate } = useContext(LangContext)
  const prevPage = (event) => { if (current > 1) { onChange(current - 1)}else {event.preventDefault()}}
  const nextPage = (event) => { if (current < totalPages) {onChange(current + 1)} else {event.preventDefault();} }


  const getPageNumber = (pageNumber) => {
    const isCurrent = pageNumber === current

    const aria = { 'aria-label': `Go to page ${pageNumber}` }
    // Conditionally adding the aria-current attribute as it cannot be done in JSX
    if (isCurrent) aria['aria-current'] = 'page'

    return (
      <li key={pageNumber} >
        <a
          href='#'
          className={`button pagination-link${isCurrent ? ' is-current is-link' : ''}`}
          onClick={(event) => { if (isCurrent) {event.preventDefault();} else {onChange(pageNumber);} }}
          onKeyDown={(e) => { if (e.key === 'Enter') onChange(pageNumber) }}
          aria-current={isCurrent ? 'true' : 'false'}
          aria-label={isCurrent ? `Page ${pageNumber}, ${translate('fab.directory.currentPage')}` : `${translate('fab.directory.goToPage')} ${pageNumber}`}
        >
          {pageNumber}
        </a>
      </li>
    )
  }

  const getEllipsis = (index) => {
    let aria;

    if(index === 1) {
        aria = totalIndex === 1 ? {'aria-label': `${translate('fab.directory.skipPage')} 2 ${translate('fab.directory.through')} ${totalPages - 4}`} : {'aria-label': `${translate('fab.directory.skipPage')} 2 ${translate('fab.directory.through')} ${current - 2}`}
    } else if(index === 5) {
        aria = totalIndex === 1 ? {'aria-label': `${translate('fab.directory.skipPage')} 6 ${translate('fab.directory.through')} ${totalPages - 1}`} : {'aria-label': `${translate('fab.directory.skipPage')} ${current + 2} ${translate('fab.directory.through')} ${totalPages - 1}`}
    }


    return <li key={Math.random()}><a className="button is-white" aria-disabled="true" onClick={e => {e.preventDefault();}} href="#" {...aria}>&hellip;</a></li>
  }

  let totalIndex = 0;
  const pageNumbers = []
  if (totalPages <= 7) {
    let i = 1
    do {
      pageNumbers.push(i)
      i += 1
    } while (i <= totalPages)
  } else if (current >= totalPages - 2) {
    pageNumbers.push(1, null, totalPages - 3, totalPages - 2, totalPages - 1, totalPages)
    totalIndex = 1;
  } else if (current <= 4) {
    pageNumbers.push(1, 2, 3, 4, 5, null, totalPages)
    totalIndex = 1;
  } else {
    pageNumbers.push(1, null, current - 1, current, current + 1, null, totalPages)
    totalIndex = 2;
  }


  return (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <a
        aria-label={current === 1 ? `${translate('fab.directory.previous1')}` : `${translate('fab.directory.previous2')}`}
        role="button"
        href='#'
        onClick={prevPage}
        onKeyDown={(e) => { if (e.key === 'Enter') prevPage() }}
        className={`button pagination-previous ${current === 1 ? 'is-disabled' : ''}`}
        aria-disabled={current === 1 ? "true" : "false"}
      >
        {translate('common.prev')}
      </a>
      <a
        aria-label={current >= totalPages ? `${translate('fab.directory.next1')}` : `${translate('fab.directory.next2')}`}
        role="button"
        href='#'
        onClick={nextPage}
        onKeyDown={(e) => { if (e.key === 'Enter') nextPage() }}
        className={`button pagination-next ${current >= totalPages ? 'is-disabled' : ''}`}
        aria-disabled={current >= totalPages ? "true" : "false"}
      >
        {translate('common.next')}
      </a>
      <ul className="pagination-list">
        {pageNumbers.map((n, index) => { if(!n) {return getEllipsis(index)} else {return getPageNumber(n)}})}
      </ul>
    </nav>
  )
}

export default Pagination

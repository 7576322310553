import React, { useState, useEffect, useContext, useRef } from 'react'
import { LangContext } from '../../../../localization/localizationContext'
import useAddressPredictions from '../../../../utils/hooks/useAddressPredictions'
import { centreCompare, searchBrokers } from '../../../../utils/mapUtility'
import { FAB_VIEW } from '../../../../utils/consts'
import Result from './Result'
import LookAheadSearchResults from './LookAheadSearchResults'
import UnavailableErrorText from './UnavailableErrorText'
import SearchFilter from '../../../common/ui/SearchFilter'

/**
 * @summary Loads the scrollable search pane for the Find A Broker page
 *
 * @param {array.<object>} props.brokerData Collection of broker data from DB
 * @param {boolean} atAcceptableZoom If the map is at a zoom level that should display brokers
 * @param {object} props.mapCenter Lat/Lng coordinates for the map center
 * @param {object} props.selectedPinLoc Lat/Lng coordinates for currently selected pin
 * @param {boolean} props.viewBrokersLoaded If the brokers pins have been loaded in the map
 * @param {array.<object>} props.visibleBrokers Collection of brokers currently visible on the map
 * @param {function} props.handleUpdateBrokers Handler for selecting a broker
 * @param {function} props.handleSelectedPin Handler for selecting a broker
 * @param {function} props.handleUpdateCenter Handler for updating the new center of the map
 */
// eslint-disable-next-line max-len
const BrokerDetails = ({ postalCode, brokerData, atAcceptableZoom, mapCenter, selectedBrokerCode, personalCommercialFilter, lifeGroupBenefitsFilter, setPersonalCommercialFilter, setLifeGroupBenefitsFilter, viewBrokersLoaded, visibleBrokers, handleSelectedPin, handleUpdateCenter, brokerCommError, setForceZoomCheck, brokerUpdated }) => {
  const { translate } = useContext(LangContext)
  const [searchTerm, setSearchTerm] = useState(postalCode)
  const [showLookAhead, setShowLookAhead] = useState(false)
  const [brokerPredictions, setBrokerPredictions] = useState([])
  const [selectedPrediction, setSelectedPrediction] = useState(null)
  const [addressPredictionSelected, setAddressPredictionSelected] = useState(false)//indicate if user selected a address prediction, used to set the focus on first result 
  const { predictions, getCoordinates } = useAddressPredictions(searchTerm)
  const addressPredictions = predictions.slice(0, 4)
  const searchRef = useRef()
  const predictionsRef = useRef()
  const searchTermLength = searchTerm.trim().length
  const showNoResultsFound = (visibleBrokers.length) === 0 && viewBrokersLoaded && !showLookAhead
  const predictionsLength = brokerPredictions.length + addressPredictions.length //number of all the look ahead items

  useEffect(() => {    
    if (searchTerm.trim().length >= 3) {
      const searchResults = searchBrokers(brokerData, searchTerm)
      const brokersClosestToMapCenter = searchResults.sort(centreCompare(mapCenter)).slice(0, 4)
      setBrokerPredictions(brokersClosestToMapCenter)
    }
  }, [searchTerm, brokerData, mapCenter])

  /**
   * Sets up event listeners to hide look ahead when clicking outside of the box
   */
  useEffect(() => {
    const handleClickOutsidePredictions = (e) => {
      if (predictionsRef.current) {
        if (!predictionsRef.current.contains(e.target)) {
          setShowLookAhead(false)
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutsidePredictions)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePredictions)
    }
  }, [searchRef, predictionsRef])

  //set whether show look ahead or not. 
  const setLookAhead = () => {
    //if the search bar get focus, and searchTerm length>3, and there're any predictions, show the look ahead
    if (document.activeElement === searchRef.current && searchTerm.length >= 3 && predictionsLength > 0) {
      setShowLookAhead(true)
      setSelectedPrediction(null)
    } else {
      setShowLookAhead(false)
    }
  }

  useEffect(() => { searchRef.current.setAttribute('aria-expanded', showLookAhead) }, [showLookAhead])

  useEffect(setLookAhead, [searchTerm, predictionsLength]);

  //set the focus to be the first result if user selected address prediction
  useEffect(() => {
    //make sure broker has been updated
    if (addressPredictionSelected && brokerUpdated) {
      let firstResult = document.querySelector('.search-result-list li:first-child');

      if (firstResult) {
        firstResult.focus()
        setAddressPredictionSelected(false)
      }
    }
  }, [addressPredictionSelected, brokerUpdated])

 /**
   * @description Insurance type filters are reset when a User inputs a new search term
   */
 const updateSearchTerm = (newSearchTerm) => {
  if (!personalCommercialFilter) setPersonalCommercialFilter(true)
  if (!lifeGroupBenefitsFilter) setLifeGroupBenefitsFilter(true)
  setSearchTerm(newSearchTerm)
}

  /**
   * @description Run on pin selection
   */
  const handleUpdateCenterHelper = (coords, code) => {
    setShowLookAhead(false)
    handleSelectedPin(coords, code)
    handleUpdateCenter(coords, code)
  }

  /**
   * @description Gets geographic coordinates for brokers or google lookahead results. Fetching
   *  Google coordinates are asynchronous.
   *
   * @param {int} index Index of desired look ahead search result
   * @returns {object} Lat/Lng coordinates
   */
  const getPinCoordinates = async (index) => {
    
    const selected = brokerPredictions.concat(addressPredictions)[index]
    const isBroker = Boolean(selected.code)
    if (isBroker) {
      return selected.position
    } else {
      const coords = await getCoordinates(selected.place_id)
      return coords
    }
  }

  /**
   * @description Gets the broker code if we selected a broker.
   *
   * @param {int} index Index of desired look ahead search result
   * @returns {object} Broker code or empty string
   */
  const getBrokerCode = (index) => {
    const selected = brokerPredictions.concat(addressPredictions)[index]
    const isBroker = Boolean(selected.code)
    if (isBroker) {
      return selected.code
    } else {
      return ''
    }
  }

  /**
   * @description Run on look ahead selection
   *
   * @param {int} index Index of desired look ahead search result
   */
  const handlePredictionSelection = async (index) => {
    const coords = await getPinCoordinates(index)
    const code = getBrokerCode(index)
    setShowLookAhead(false)
    handleUpdateCenterHelper(coords, code)

    // Do the zoom checks when we select a predicted address, but not for
    // an actual brokerage.
    if (code.length === 0) {
      setForceZoomCheck(true)
      setAddressPredictionSelected(true)
    }
  }


  /**
   * @description Handles keyboard events like navigating and selecting lookahead results.
   */
  const handleKeyDown = (e) => {
    const { key } = e
    const predictionsLength = brokerPredictions.length + addressPredictions.length
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      e.preventDefault()
      if (key === 'ArrowUp') {
        if (selectedPrediction === 0) {
          searchRef.current.focus()
        } else {
          setSelectedPrediction((prev) => prev - 1)
        }
      } else if (key === 'ArrowDown' && selectedPrediction < predictionsLength - 1) {
        setSelectedPrediction((prev) => (prev !== null ? prev + 1 : 0))
      }
    } else if (key === 'Enter' && selectedPrediction === null && predictionsLength > 0) {
      handlePredictionSelection(0)
      searchRef.current.blur()
    } else if (key === 'Escape' && showLookAhead) {
      setShowLookAhead(false)
    }// event.key provides a "descriptive" title a key. For characters it will just be the character
    else if (key.length === 1) {
      searchRef.current.focus()
    }
  }



  /**
   * @description Checks if the provided broker is currently selected
   *
   * @param {object} result Broker entry
   */
  const checkActive = (result) => selectedBrokerCode === result.code

  const searchText = (searchTermLength > 0 && searchTermLength < 3) && (
    <span id="fab-search-error" className="is-size-7">{translate('fab.details.pleaseEnterAtLeast3Letters')}</span>
  )

  const noResultsFound = (
    <p className="has-text-centered" tabIndex={0}>
      <span className="has-text-weight-semibold">{translate('fab.noBrokers')}</span>
      <br />
      {translate('fab.enterNewSearch')}
    </p>
  )

  const brokerCommErrorText = (
    <UnavailableErrorText center={1} />
  )


  let errorText

  if (brokerCommError) {
    errorText = brokerCommErrorText
  } else if (!atAcceptableZoom || showNoResultsFound) {
    errorText = noResultsFound
  }

  return (
    <>
      <div className="fab-search-input" ref={predictionsRef}>
        <div className="field mb-0">
          <label className="label" htmlFor="fab-search">{translate('fab.details.enterLocationName')}</label>
          <div className="control" >
            <input
              type="text"
              size={50}
              id='fab-search'
              autoComplete='off'
              aria-expanded='false'
              aria-describedby='fab-search-instructions fab-search-error'
              className="input"
              value={searchTerm}
              onChange={(e) => { updateSearchTerm(e.target.value) }}
              onKeyDown={handleKeyDown}
              ref={searchRef}
              onFocus={setLookAhead}
            />

          </div>
          <span id="fab-search-instructions" className="ww-visually-hidden">{translate('fab.suggestedResultsInstruction')}</span>
          {searchText}
        </div>
        {showLookAhead && (
          <LookAheadSearchResults
            addresses={addressPredictions}
            brokers={brokerPredictions}
            getCoordinates={getCoordinates}
            selectedPrediction={selectedPrediction}
            handleClick={handlePredictionSelection}
            handleKeyDown={handleKeyDown}
            isVisible={showLookAhead}
          />
        )}
        <SearchFilter
          personalCommercialFilter={personalCommercialFilter}
          lifeGroupBenefitsFilter={lifeGroupBenefitsFilter}
          setPersonalCommercialFilter={setPersonalCommercialFilter}
          setLifeGroupBenefitsFilter={setLifeGroupBenefitsFilter}
        />
      </div>

      <div className='fab-search-results'>
        {errorText}
        {atAcceptableZoom &&
          <ul className='is-unstyled mt-0 search-result-list'>
            {visibleBrokers.map((result, index) => (
              <Result
                key={result.code}
                broker={result}
                highlighted={checkActive(result)}
                handleUpdateCenter={handleUpdateCenterHelper}
                viewType={FAB_VIEW.MAP}
                index={index}
              />
            ))}
          </ul>}

      </div>
    </>

  )
}


export default BrokerDetails

import React, { useContext } from 'react'
import { LangContext } from '../../../localization/localizationContext'

const SearchFilter = ({personalCommercialFilter, lifeGroupBenefitsFilter, setPersonalCommercialFilter, setLifeGroupBenefitsFilter}) => {
  const { translate } = useContext(LangContext)

  return (
    <fieldset className='fab-filters'>
      <legend>
        <div className='mt-4 mr-4'>
          {translate('fab.details.filterByInsurance')}
        </div>
      </legend>
      <div className='mt-4 mr-4 fab-filter-item'>
        <input 
          type='checkbox' 
          id='personal'
          name='Personal and commercial' 
          checked={personalCommercialFilter}
          onChange={() => setPersonalCommercialFilter((state) => !state)}
        />
        <label htmlFor='personal' className='checkbox ml-1'>
          {translate('fab.details.personalCommercialFilter')}
        </label>
      </div>
      <div className='mt-4 mr-4 fab-filter-item'>
        <input 
          type='checkbox' 
          id='life'
          name='Life and group benefits' 
          checked={lifeGroupBenefitsFilter}
          onChange={() => setLifeGroupBenefitsFilter((state) => !state)}
        />
        <label htmlFor='life' className='checkbox ml-1'>
          {translate('fab.details.lifeAndGroupBenefitsFilter')}
        </label>
      </div>
    </fieldset>
  )
}

export default SearchFilter
import React, { useState, useEffect, useContext, useRef } from 'react'
import canada from '../../../assets/img/ca.svg'
import wawanesaAssuranceBlue from '../../../assets/img/Wawanesa_wordmark_blue.svg'
import wawanesaInsuranceBlue from '../../../assets/img/Wawanesa_wordmark_blue.svg'
import { LangContext } from '../../../localization/localizationContext'
import LayoutLink from '../../../components/layout/layout-parts/LayoutLink'
import { REACT_APP_SITE, LIFE_SITE_DOMAIN } from '../../../utils/consts'
import { getHrefForLangBtn } from '../../../utils/utility'
import { Link } from 'react-router-dom'



const Header = ({ nav }) => {
  const { translate, lang, toggleLang } = useContext(LangContext)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1088)
  // For mobile views, index of currently open sub-menu
  const [openSubMenu, setOpenSubMenu] = useState()
  const navBarRef = useRef()
  const navStyles = { display: menuIsOpen || isDesktop ? 'block' : 'none' }
  //const settingsStyles = { display: menuIsOpen || isDesktop ? 'flex' : 'none' }
  const mobileButtonClasses = `fa fa-fw ${menuIsOpen ? 'fa-times' : 'fa-bars'}`
  const toggleMenu = () => setMenuIsOpen((prev) => !prev)

  // Adding and removing event listeners for window resizing on mount/unmount
  useEffect(() => {
    const onResize = () => {
      setMenuIsOpen(false)
      setIsDesktop(window.innerWidth >= 1088)
    }

    // Closes active sub-menu when clicking outside of nav bar
    const onClick = (e) => {
      if (!navBarRef.current.contains(e.target)) {
        setOpenSubMenu()
      }
    }

    window.addEventListener('resize', onResize)
    window.addEventListener('mousedown', onClick)
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('mousedown', onClick)
    }
  }, [])

  // Closing all sub-menus if the menu is menu drawer is toggled
  useEffect(() => setOpenSubMenu(), [menuIsOpen])

  const handleOpenSubMenu = (newSubMenu) => {
    setOpenSubMenu((prevSubMenu) => (prevSubMenu === newSubMenu ? null : newSubMenu))
  }

  const handleRegionSelect = (e) => {
    window.location.href = `${e.target.value}`;
  }

  const getNavSubMenu = (subMenu) => (
    subMenu.map((sub) => {
      if (sub.children.length > 0) {
        const subCategoryItems = sub.children.map((cat) => (
          <li key={cat.href}>
            <LayoutLink
              href={cat.href}
            >
              {cat.title}
            </LayoutLink>
          </li>
        ))

        return (
          <li key={sub.title} className="subcategory">
            <h3>{sub.title}</h3>
            <ul className="ww-nav-submenu-section">
              {subCategoryItems}
            </ul>
          </li>
        )
      } else {
        return (
          <li key={sub.title}>
            <LayoutLink href={sub.href}>
              {sub.title}
            </LayoutLink>
          </li>
        )
      }
    })
  )
  
  const getNav = () => {
    const topLevelNav = nav.map((top, index) => {
      if (top.children.length > 0) {
        const subMenuItems = getNavSubMenu(top.children)
        return (
          <li
            key={top.title}
            className={`ww-nav-submenu${openSubMenu === index ? ' open' : ''}`}
          >
            <LayoutLink
              href="#"
              attributes={{
                id: top.id,
                'aria-haspopup': 'true',
                'aria-expanded': openSubMenu === index,
                onClick: () => handleOpenSubMenu(index),
              }}
            >
              {`${top.title} `}
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </LayoutLink>
            <ul>
              {subMenuItems}
            </ul>
          </li>
        )
      } else {
        if (top.title.includes('COVID-19')) {
          return (
            <li key={top.title}>
              <LayoutLink
                href={top.href}
                attributes={{
                  className: 'has-text-weight-bold'
                }}
              >
                {top.title}
              </LayoutLink>
            </li>
          )
        } else if (top.title.includes('Search') || (top.title.includes('Recherche'))) {
          return (
            <li key={top.title}>
              <LayoutLink
                href={top.href}
              >
                {`${top.title.split('<')[0]} `}
                <i className="fa-light fa-magnifying-glass"></i>
              </LayoutLink>
            </li>
          )
        } else {
          return (
            <li key={top.title}>
              <LayoutLink
                href={top.href}
              >
                {top.title}
              </LayoutLink>
            </li>
          )
        }
      }
    })

    return (
      <div className="ww-nav-bar" ref={navBarRef} data-testid="nav-bar">
        <ul>{topLevelNav}</ul>
      </div>
    )
  }

  return (
    <header className="ww-header">
      <div className="ww-skip-nav">
        <a href="#main-content" className="button is-link is-outlined ml-1 mt-1">{translate('layout.nav.content')}</a>
      </div>
      <div className="container ww-header-top">
        <div className="ww-logo">
          <LayoutLink
            href="/canada"
            attributes={{
              id: 'wwheader-logo',
              className: "ww-img-link",
            }}
          >
            <img
              alt="Wawanesa"
              width="245"
              height="48"
              src={lang.CODE === 'fr' ? wawanesaAssuranceBlue : wawanesaInsuranceBlue}
            />
          </LayoutLink>
        </div>
        <button
          aria-label="Open Menu"
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
          id="ww-menu-btn"
          className="button is-white is-hidden-desktop ww-menu-btn"
          onClick={toggleMenu}
        >
          <i className={mobileButtonClasses} />
          <span className="ww-visually-hidden">Menu</span>
        </button>
        <div id="ww-settings" className="ww-settings">
          <form className="ww-region" name="chooseCountry" method="post">
            <div className="field">
              <label htmlFor="region" className="ww-visually-hidden">{translate('layout.nav.countrySelect.choose')}</label>
              <div className="control has-icons-left">
                <div className="select">
                  <select id="region" name="region" onChange={(e) => {handleRegionSelect(e)}}>
                    <option value={`/canada/?language_id=${lang.CODE === 'fr' ? '102' : '1'}`}>{translate('layout.nav.countrySelect.canada')}</option>
                    <option value={LIFE_SITE_DOMAIN}>{translate('layout.nav.countrySelect.life')}</option>
                    <option value={`${REACT_APP_SITE}/us/california`}>{translate('layout.nav.countrySelect.california')}</option>
                    <option value={`${REACT_APP_SITE}/us/oregon`}>{translate('layout.nav.countrySelect.oregon')}</option>
                  </select>
                </div>
                <span className="icon is-left">
                  <img className="ww-country-icon mx-2" src={canada} alt={translate('wawanesa')} height="25" width="34" />
                </span>
              </div>
            </div>
          </form>
          <div className="ww-language">
            <Link to={getHrefForLangBtn()} className="button is-white" onClick={toggleLang}>{translate('otherLanguage')}</Link>
          </div>
          <form
            id="searchForm"
            name="searchForm"
            className="ww-search"
            action={`${REACT_APP_SITE}/canada/ca-search.html`}
          >
            <div className="field has-addons">
              <label htmlFor="searchbar" className="ww-visually-hidden">{translate('layout.nav.search')}</label>
              <div className="control make-full-width">
                <input
                  id="searchbar"
                  className="input"
                  type="text"
                  placeholder={translate('layout.nav.search')}
                  name="q"
                />
              </div>
              <div className="control">
                <button type="submit" className="button is-link">
                  <i className="fa fa-search" aria-hidden="true" />
                  <span className="ww-visually-hidden">{translate('layout.nav.search')}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <nav id="ww-nav" style={navStyles}>
        <div className="container">
          {getNav()}
          <div id="ww-settings-mobile" className="ww-settings-mobile">
            <form className="ww-region-mobile" name="chooseCountry" method="post">
              <div className="field">
                <label htmlFor="region-mobile" className="ww-visually-hidden">{translate('layout.nav.countrySelect.choose')}</label>
                <div className="control has-icons-left">
                  <div className="select">
                    <select id="region-mobile" name="region-mobile" onChange={(e) => {handleRegionSelect(e)}}>
                      <option value={`/canada/?language_id=${lang.CODE === 'fr' ? '102' : '1'}`} defaultValue="">{translate('layout.nav.countrySelect.canada')}</option>
                      <option value={LIFE_SITE_DOMAIN}>{translate('layout.nav.countrySelect.life')}</option>
                      <option value={`${REACT_APP_SITE}/us/california`}>{translate('layout.nav.countrySelect.california')}</option>
                      <option value={`${REACT_APP_SITE}/us/oregon`}>{translate('layout.nav.countrySelect.oregon')}</option>
                    </select>
                  </div>
                  <span className="icon is-left">
                    <img className="ww-country-icon mx-2" src={canada} alt={translate('wawanesa')} height="25" width="34" />
                  </span>
                </div>
              </div>
            </form>
            <form className="ww-search-mobile" id="searchFormMobile" name="searchForm" action={`${REACT_APP_SITE}/canada/ca-search.html`}>
              <div className="field has-addons">
                <label htmlFor="searchbar-mobile" className="ww-visually-hidden">{translate('layout.nav.search')}</label>
                <div className="control is-fullwidth">
                  <input id="searchbar-mobile" className="input" type="text" placeholder={translate('layout.nav.search')} name="q" />
                </div>
                <div className="control">
                  <button type="submit" className="button is-link is-light"><i className="fa fa-search" aria-hidden="true"></i><span className="ww-visually-hidden">{translate('layout.nav.search')}</span></button>
                </div>
              </div>
            </form>
            <div className="ww-language">
              <Link to={getHrefForLangBtn()} id="wwheader-lang-select-mobile" className="button is-info is-fullwidth" onClick={toggleLang}>{translate('otherLanguage')}</Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header

import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import axios from 'axios'
import { ROUTES, ENDPOINTS } from '../../../utils/consts'
import WaitFor from '../../../components/common/ui/WaitFor'
import { brokerSort } from '../../../utils/mapUtility'
import FindABroker from './find-a-broker/FindABroker'
import BrokerDirectory from './broker-directory/BrokerDirectory'
import BrokerProfile from './broker-profile/BrokerProfile'
import AccessibleNavigationAnnouncer from '../../common/utility/AccessibleNavigationAnnouncer'

/**
 * @summary Handles routes for Find a Broker pages, Broker data is fetched here
 */
const Broker = () => {
  const [brokers, setBrokers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [brokerCommError, setBrokerCommError] = useState(true)

  // Sorts brokers alphabetically on the following properties:
  //  - name
  //  - province
  //  - city
  //  - address
  const sortedBrokers = brokers.sort(brokerSort)

  const setBrokerAndErrorDetails = (data, commErr) => {
    setBrokers(data)
    setBrokerCommError(commErr)
  }

  const getParamValue = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let brokerPostalCode = '';
    const regex = /^([a-zA-Z]\d[a-zA-Z](?:\s?)(\d[a-zA-Z]\d)?)$/;
    if(regex.test(urlParams.get('PostalCode')) && urlParams.get('PostalCode')) {
      brokerPostalCode = urlParams.get('PostalCode');
    } 

    return brokerPostalCode;
  }

  useEffect(() => {
    setIsLoading(true)
    axios.get(ENDPOINTS.BROKER_DATA)
      .then((res) => {
        try {
          if (typeof res.data !== 'object' || res.data.length === 0) {
            setBrokerAndErrorDetails([], true)
          } else {
            setBrokerAndErrorDetails(res.data, false)
          }
        } catch (jsonErr) {
          setBrokerAndErrorDetails([], true)
        }
      })
      .catch(() => {
        setBrokerAndErrorDetails([], true)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <WaitFor isLoading={isLoading}>
      <AccessibleNavigationAnnouncer/>
      <Switch>
        <Route path={[ROUTES.FIND_A_BROKER, ROUTES.FIND_A_BROKER_FR]}>
          <FindABroker brokers={brokers} brokerCommError={brokerCommError} getParamValue={getParamValue()}/>
        </Route>
        <Route path={[ROUTES.BROKER_DIRECTORY, ROUTES.BROKER_DIRECTORY_FR]}>
          <BrokerDirectory brokers={sortedBrokers} brokerCommError={brokerCommError}/>
        </Route>
        <Route path={[`${ROUTES.BROKER_PROFILE}/:code`, `${ROUTES.BROKER_PROFILE_FR}/:code`]}>
          <BrokerProfile brokers={brokers} brokerCommError={brokerCommError} />
        </Route>
      </Switch>
    </WaitFor>
  )
}

export default Broker

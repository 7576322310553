import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { LangContext } from '../../../../localization/localizationContext'
import { Link } from 'react-router-dom'
import { FAB_VIEW } from '../../../../utils/consts'

/**
 * @summary Loads a individual search result for the Find A Broker left pane
 *
 * @param {object} props.broker Broker object from db
 * @param {boolean} props.highlighted Selected status of entry
 * @param {function} props.handleUpdateCenter Handles updating the map when a broker is selected
 */
const Result = ({ broker, highlighted, handleUpdateCenter, viewType, index }) => {
  const { translate, lang } = useContext(LangContext)
  const resultRef = useRef()
  useEffect(() => {
    if (highlighted) {
      resultRef.current.focus();
    }
  }, [highlighted])


  const updateCenter = () => {
    const coords = { lat: broker.position.lat, lng: broker.position.lng }
    handleUpdateCenter(coords, broker.code)
  }

  let brokerURLNoProtocol
  if (broker.website) {
    brokerURLNoProtocol = (broker.website).replace(/^https?:\/\//i, '')
  }

  //convert phone number from '(204) 261-9016' to '204-261-9016'
  const convertPhoneNumber = (phoneNumber) => {
    let NumRemoveNonDigit = phoneNumber.replace(/\D/g, '');
    return NumRemoveNonDigit.slice(0, 3) + '-' + NumRemoveNonDigit.slice(3, 6) + '-' + NumRemoveNonDigit.slice(6);
  }

  const getBrokerAddress = (address, city, province) => {
    let tempAddress = (address) ? `${address},` : '';
    let tempCity = (city) ? `${city},` :  '';
    let tempProvince = (province) ? `${province}` : '';
    let brokerAddress =  `${tempAddress} ${tempCity} ${tempProvince}`;
    return brokerAddress;
  }

  const getInsuranceTypes = (broker) => {
    if(broker.ispcbroker && !broker.islifebroker) {
      return `${translate('fab.details.offers')} ${translate('fab.details.personalCommercialOffering').toLowerCase()}`;
    } else if(!broker.ispcbroker && broker.islifebroker) {
      return `${translate('fab.details.offers')} ${translate('fab.details.lifeAndGroupBenefitsOffering').toLowerCase()}`;
    }
    return `${translate('fab.details.offers')} ${translate('fab.details.bothPCandLifeOffering')}`;
  }

  const brokerPhone = broker.phone1 || broker.phone2
  const brokerAddress = getBrokerAddress(broker.address, broker.city, broker.province);
  const brokerContact = {
    phone: brokerPhone && (
        <a aria-label={`${translate('fab.details.call')} ${convertPhoneNumber(brokerPhone)}`} className='href is-midtone' href={`tel:${convertPhoneNumber(brokerPhone)}`}>
          <i className="fa fa-phone mr-2" aria-hidden="true"></i>
          {`${brokerPhone}`}
        </a>
    ),
    directions: (
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${broker.position.lat},${broker.position.lng}`}
          className='href is-midtone'
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-solid fa-route mr-2"></i>
          {`${translate('fab.details.directions')}`}
        </a>
    ),
    email: broker.email && (
        <a href={`mailto:${broker.email}`} className='href is-midtone'>
          <i className="fa-solid fa-at mr-2"></i>
          {broker.email}
        </a>
    ),
    website: brokerURLNoProtocol && (
        <a
          className='href is-midtone'
          href={`//${brokerURLNoProtocol}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-solid fa-globe mr-2"></i>
          {`${brokerURLNoProtocol}`}
        </a>
    ),
  }

  let brokerResult

  switch (viewType) {
    case FAB_VIEW.BROKER_DIRECTORY:
      brokerResult = (
        <li className="ww-fa-directory-result" data-testid="fab-listing">
          <h2 className='is-size-5'>
            <Link to={translate('fab.route.brokerProfile') + `/${broker.code}`} hrefLang={lang.CODE} className="ww-broker-name">{broker.name}</Link>
          </h2>
          {brokerAddress && <address className='is-normal'>{brokerAddress}</address>}
          <p>{getInsuranceTypes(broker)}</p>
          {brokerPhone && <a href={`tel:${brokerPhone.replace(/\D/g, '')}`} aria-label={`Call ${brokerPhone}`}> <i className="fa fa-phone mr-2" aria-hidden="true"></i>{brokerPhone}</a>}
        </li>
      )
      break
    case FAB_VIEW.PROFILE:
      brokerResult = (
        <>
          <p className='has-text-weight-semibold mb-0'>{translate('common.geo.address')}</p>
          <address className='is-normal'>{broker.address} <br /> {getBrokerAddress('', broker.city, broker.province)}</address>
          <p>{getInsuranceTypes(broker)}</p>
          {brokerContact.phone && <p>{brokerContact.phone}</p>}
          {brokerContact.directions && <p>{brokerContact.directions}</p>}
          {brokerContact.email && <p>{brokerContact.email}</p>}
          {brokerContact.website && <p>{brokerContact.website}</p>}
        </>
      )
      break
    default: // FAB_VIEW.MAP
      brokerResult = (
        <li className={`fab-item${highlighted ? ' active' : ''}`} data-testid="fab-listing" tabIndex={0} role='button' aria-describedby={`item-${index}`} onClick={updateCenter} ref={resultRef} onKeyDown={(e) => { if (e.key === 'Enter' && e.target === document.activeElement) { updateCenter() } }}>
          <div id={`item-${index}`} className='is-flex is-align-items-center is-justify-content-space-between'>
            <div>
              <h2 className='is-size-6 is-size-5-tablet mb-0'>{broker.name}</h2>
              <address className='is-normal is-inline-block'>
                {getBrokerAddress(broker.address, broker.city, broker.province)}
              </address>
              <p>
                {getInsuranceTypes(broker)}
              </p>
            </div>
            <div className="fab-item-showing-marker">
              <i className={`${highlighted ? 'fa-solid has-text-link fa-location-dot' : 'fa-thin has-text-grey fa-location-pin'} is-size-2 ml-3`}></i>
              <span className="ww-visually-hidden">{highlighted ? translate('fab.currentHighlight') : translate('fab.notHighlight')}</span>
            </div>
          </div>
          {brokerContact.directions && <p className='my-2'>{brokerContact.directions}</p>}
          {brokerContact.phone && <p className='my-2'>{brokerContact.phone}</p>}
          {brokerContact.email && <p className='my-2'>{brokerContact.email}</p>}
          {brokerContact.website && <p className='my-2'>{brokerContact.website}</p>}

        </li>
      )
  }

  return brokerResult
}

// eslint-disable-next-line react/forbid-prop-types
Result.contextTypes = { scrollArea: PropTypes.object }

export default Result

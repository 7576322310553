/**
 * Environment Configuration
 */
export const CONFIG_NODE_ENV = "prd"
export const CONFIG_APP_SITE = "https://www.wawanesa.com"
export const CONFIG_CAPTCHA_PUBLIC = "6LdokyQUAAAAAIJ3jP46fxnnTlJe2u_IEnW-3v0g"
export const CONFIG_GOOGLE_API_KEY = "AIzaSyBtPL0yF1fxwJALC8rkNASW1I6jL_PN2Sc"
export const CONFIG_GTM_TAG_ID = "GTM-WK8KZH"
export const CONFIG_BROKER_DATA = "https://fab-api.wawanesa.com/web/canada/api/brokers"
export const CONFIG_NAVIGATION = "https://fab-api.wawanesa.com/web/canada/api/nav"
export const CONFIG_LIFE_ENDPOINT = "https://www.wawanesalife.com"


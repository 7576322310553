import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { LangContext } from '../../../localization/localizationContext'

function AccessibleNavigationAnnouncer() {
  const { translate} = useContext(LangContext)
  const [message, setMessage] = useState('')
  const location = useLocation()

  const getMessage = () => {
    let message;
    let page = document.querySelector('h1').innerText;
    if(location.pathname.slice(1) == 'broker' || location.pathname.slice(1) == 'courtier') {
        message = `${translate('fab.navigateTo2')} ${page}`;
    } else { 
      message = `${translate('fab.navigateTo1')} ${page}`;
    } 
    return message;
  }

  useEffect(() => {
    if (location.pathname.slice(1)) {
      let message = getMessage();
      setMessage(`${message}`)
    } else {
      setMessage('')
    }
  }, [location])



  return (
    <span className="ww-visually-hidden" role="status" aria-live="polite" aria-atomic="true">
      {message}
    </span>
  )
}

export default AccessibleNavigationAnnouncer
import React, { useContext } from 'react'
import { LangContext } from '../../../../localization/localizationContext'

const UnavailableErrorText = ({ center }) => {
  const { translate } = useContext(LangContext)

  const textAlign = center ? {} : { 'text-align': 'left' }

  return (
    <p className="ww-list-noresults" style={textAlign}>
      <span>{translate('fab.fabUnavailable')}</span>
      <br />
      {translate('fab.tryAgainLater')}
    </p>
  )
}

export default UnavailableErrorText

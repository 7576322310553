import { computeDistanceBetween } from 'spherical-geometry-js'

/**
 * @description Sort function to sort an array based on proximity to a given set of coordinates
 *
 * @param {object} baseCoords {lat, lng} Coordinates on which to base the comparison
 */
export const centreCompare = (baseCoords) => (a, b) => {
  const da = computeDistanceBetween(baseCoords, a.position)
  const db = computeDistanceBetween(baseCoords, b.position)

  if (da < db) {
    return -1
  }

  if (da > db) {
    return 1
  }

  /* eslint-disable no-param-reassign */
  a.dist = da
  b.dist = db
  /* eslint-enable no-param-reassign */
  return 0
}

/**
 * @description Sorts broker weighted on name, province, city, and address properties
 */
export const brokerSort = (a, b) => {
  if (a.name !== b.name) {
    return a.name > b.name ? 1 : -1
  } else if (a.province !== b.province) {
    return a.province > b.province ? 1 : -1
  } else if (a.city !== b.city) {
    return a.city > b.city ? 1 : -1
  } else if (a.address !== b.address) {
    return a.address > b.address ? 1 : -1
  }
  return 0
}

export const searchBrokers = (brokers, search) => {
  const searchResults = brokers.filter((b) => {
    const formattedBroker = [b.name, b.address, b.city, b.province, b.postalCode].join(' ').toLocaleLowerCase()
    const formattedSearch = search.trim().toLocaleLowerCase()
    return formattedBroker.includes(formattedSearch)
  })
  return searchResults
}

export const filterBrokers = (brokers, personalCommercialFilter, lifeGroupBenefitsFilter) => {
  if (personalCommercialFilter && !lifeGroupBenefitsFilter) {
    return brokers.filter((b) => b.ispcbroker === true && b.islifebroker === false)
  } else if (lifeGroupBenefitsFilter && !personalCommercialFilter) {
    return brokers.filter((b) => b.islifebroker === true)
  } else if (!lifeGroupBenefitsFilter && !personalCommercialFilter) {
    return brokers.filter((b) => b.ispcbroker === false && b.islifebroker === false)
  }
  return brokers
}

import React, { useState, createContext } from 'react'
import { useLocation } from "react-router-dom"
import { LANG} from '../utils/consts'
import EN from '../localization/language/en'
import FR from '../localization/language/fr'
import GLOBAL from '../localization/language/global'
import { getProperty} from '../utils/utility'
import { getLanguage, setLanguageToLocalStorage } from '../utils/language'

const translations = {
  en: EN,
  fr: FR,
}

/**
 * Hierarchy for detecting/setting language
 * 1. language of URI (/broker is English, /courtier French...)
 * 2. localStorage.getItem("dotCMSLanguage") localStorage.setItem("dotCMSLanguage", ID)
 * 3. Browser language
 * 4. English
 */

 let preferredLanguage = getLanguage(window.location.pathname)

export const LangContext = createContext()

export const Store = (language) => {
  const [curLang, setCurLang] = useState(language)
  const [redirect, setRedirect] = useState('/')
  const location = useLocation()
  preferredLanguage = getLanguage(location.pathname)

  /**
   * @description Override for the useState setLang to ensure that the language can only be set
   *   safely with valid languages. Does nothing if an invalid param is passed.
   *
   * @param {string|number} id Param matching either the language code (en|fr) or id (1|102)
   */
  const setLangOverride = (id) => {
    const desiredLang = setLanguageToLocalStorage(id)
    if (desiredLang && desiredLang.CODE !== curLang.CODE) {
      setCurLang(desiredLang)
    }
  }

  /**
   * @description Translates language keys to text in the current language.
   *
   * @param {string|Array<string>} key Dot notation string correlating to entry in
   * /src/localization/language/*.js in string format. An array can also be passed in and the
   * results will be concatenated together.
   *
   * @returns {string} Translation for key in current language. Returns the key if not found.
   */
  const translate = (key) => {
    const getTranslation = (k) => (
      getProperty(translations[curLang.CODE], k) || (getProperty(GLOBAL, k) || k)
    )

    //  If an array is passed in, each item will get translated and concatenated together
    if (Array.isArray(key)) {
      return key.map((k) => getTranslation(k)).join('')
    } else {
      return getTranslation(key)
    }
  }

  /**
   * @description Switches current language from English to French and vice versa.
   */
  const toggleLang = () => {
    const newLanguage = curLang.CODE === LANG.EN.CODE ? LANG.FR.CODE : LANG.EN.CODE
    setLangOverride(newLanguage)
  }

  // Properties that available through context
  const store = {
    lang: curLang,
    setLang: setLangOverride,
    toggleLang,
    redirect,
    setRedirect,
    translate,
  }

  return store
}

// eslint-disable-next-line react/display-name
export default ({ children }) => (
  <LangContext.Provider value={Store(preferredLanguage)}>{children}</LangContext.Provider>
)

import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { LangContext } from '../../../localization/localizationContext'
import bestsIcon from '../../../assets/img/Blue_A_SM.png'
import LayoutLink from '../../../components/layout/layout-parts/LayoutLink'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faExternalLink } from '@fortawesome/free-solid-svg-icons'

const footerNav = [
  {
    text: 'layout.nav.contact.customerCare',
    items: [
      {
        href: '/canada/contact-us/branch-and-service-offices.html',
        text: 'layout.nav.contact.main',
      },
      {
        href: '/canada/contact-us/frequently-asked-questions.html',
        text: 'layout.nav.contact.faq',
      },
      {
        href: '/canada/contact-us/inquiries-and-feedback.html',
        text: 'layout.nav.contact.inquiries',
      },
      {
        href: '/canada/contact-us/concerns-and-complaints.html',
        text: 'layout.nav.contact.concerns',
      },
    ],
  },
  {
    text: 'layout.nav.products.main',
    items: [
      {
        href: '/canada/products/automobile-insurance.html',
        text: 'layout.nav.products.auto',
      },
      {
        href: '/canada/products/home-insurance.html',
        text: 'layout.nav.products.home',
      },
      {
        href: '/canada/products/farm-insurance.html',
        text: 'layout.nav.products.farm',
      },
      {
        href: '/canada/products/business-insurance.html',
        text: 'layout.nav.products.commercial',
      },
      {
        href: '/canada/products/life-insurance.html',
        text: 'layout.nav.products.life',
      },
    ],
  },
  {
    text: 'layout.footer.discover',
    items: [
      {
        href: 'https://jobs.wawanesa.com/',
        text: 'layout.nav.contact.jobs',
      },
      {
        href: '/canada/blog/blog-posts.html',
        text: 'layout.nav.blog',
      },
      {
        href: '/canada/news-listing.html',
        text: 'layout.nav.about.news',
      },
      {
        href: '/home/history/index.html',
        text: 'layout.nav.about.history',
      },
      {
        href: '/canada/about-us/about-mission.html',
        text: 'layout.nav.about.mission',
      },
      {
        href: '/canada/about-us/about-company.html',
        text: 'layout.nav.about.info',
      },
      {
        href: '/canada/about-us/accessibility',
        text: 'layout.nav.about.accessibility'
      },
    ],
  },
]

const Footer = () => {
  const { translate, lang } = useContext(LangContext)
  const currentYear = new Date().getFullYear()


  useEffect(() => {
    const medallia = document.querySelector('#medalliaContainer');

    medallia.innerHTML = `<div class="has-radius-large has-background-grey-dark ww-feedback has-text-centered">
    <i class="fal fa-comment-dots" aria-hidden="true"></i>
    <p class="has-text-white my-3">${lang.CODE === 'en' ? 'We are always trying to improve. Let us know about your website experience.' : 'Nous essayons toujours de nous améliorer. Faites-nous part de votre expérience sur notre site Web.' }</p>
    <button style="display: inline-flex;" class="md-form-btn button is-link is-multiline" onclick="KAMPYLE_ONSITE_SDK.showForm(3787);">${lang.CODE === 'en' ? 'Submit Feedback' : 'Soumettre un commentaire '}<i class="fal fa-external-link ml-2" aria-hidden="true"></i></button>
    
    <script>
      if (window.KAMPYLE_ONSITE_SDK) {
        onsiteLoaded();
      } else {
        window.addEventListener('neb_OnsiteLoaded', onsiteLoaded);
      }
    function onsiteLoaded(){
      var neb_status = KAMPYLE_ONSITE_SDK.loadForm(3787);
      if (neb_status === true){
        var mdBtns = document.getElementsByClassName('md-form-btn');
        for (var i = 0; i < mdBtns.length; ++i) {
            mdBtns[i].style.display = "inline-flex";
          }
        }
      }
    </script>
  </div>`;

  }, [])

  const getFooterColumn = (column) => {
    const items = column.items.map((item) => (
      <li key={item.text}>
        <LayoutLink
          href={item.href}
        >
          {translate(item.text)}
        </LayoutLink>
      </li>
    ))

    return (
      <nav className="column" key={column.text}>
        <h2>{translate(column.text)}</h2>
        <ul>
          {items}
        </ul>
      </nav>
    )
  }

  const getFooterNav = (navItems) => {
    const columns = navItems.map((column) => getFooterColumn(column))

    return (
      [columns]
    )
  }

  return (
     <footer className="footer ww-footer">
      <div className="ww-footer-one">
      <div className="container">
         <div className="columns is-variable is-8">
            <div id="medalliaContainer" className="column is-one-quarter-widescreen">
              
            </div>
            {getFooterNav(footerNav)}
          </div>
        </div>   
      </div>
      <div className="ww-footer-two">
        <div className="container">
          <div className="columns">
            <div className="column">
              <p className="is-size-7">
                {`${translate('layout.footer.copyright1') + currentYear} ${translate('layout.footer.copyright2')}`}
              </p>
              <p>
                <LayoutLink
                  href="/canada/about-us/about-financial.html"
                  attributes={{ className: 'ww-ambest' }}
                >
                  <img id="logoAMBest" alt="AM Best" src={bestsIcon} />
                </LayoutLink>
              </p>
            </div>
            <div className="column is-three-quarters ww-footer-links">
              <p className="is-size-7">
                <LayoutLink href="/canada/pip/about-privacy.html">
                  {translate('layout.footer.privacyPolicy')}
                </LayoutLink>
                <span className="ww-text-divider" />
                <LayoutLink href="/canada/pip/terms-and-conditions.html">
                  {translate('layout.footer.termsAndConditions')}
                </LayoutLink>
                <span className="ww-text-divider" />
                <LayoutLink href="https://login.brokerplatform.wawanesa.com/">
                  {translate('layout.footer.brokerPortal')}
                </LayoutLink>
                <span className="ww-text-divider" />
                <LayoutLink href="https://wmic.sharepoint.com/sites/BluHome">
                  blu
                </LayoutLink>
              </p>
              <div className="ww-addthis">
                    <div className="addthis_inline_follow_toolbox"></div>
              </div>
              <Helmet><script type="text/javascript" src={translate('layout.footer.addThisURL')}></script></Helmet>
              <p className="is-size-7">
                {lang.CODE == "en" &&
                  <>
                  This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.                  
                  </>
                }
                {lang.CODE == "fr" &&
                  <>
                  Ce site est protégé par reCAPTCHA et la <a href="https://policies.google.com/privacy?hl=fr-CA">politique de confidentialité</a> et les <a href="https://policies.google.com/terms?hl=fr-CA">conditions d&apos;utilisation</a> de Google s&apos;appliquent.
                  </>
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React, { useContext, useEffect, useRef } from 'react'
import { LangContext } from '../../../../localization/localizationContext'

/**
 * @description Default export of file, renders a lookahead search box with broker and address
 *  results with key down and click events.
 *
 * @param {array.<object>} props.addresses Collection of google address suggestions
 * @param {array.<object>} props.brokers Collection of brokers to display in lookahead
 * @param {boolean} props.hide Hides or shows the lookahead box, can be used to validate that
 *  certain criteria are met such as minimum search term requirements.
 * @param {int} props.selectedPrediction The index of the selected prediction. Starts at 0 with
 *  the brokers, followed by the address results
 * @param {function} props.handleClick Click event handler
 * @param {function} props.handleKeyDown Key down event handler
 */

// eslint-disable-next-line max-len
const LookAheadSearchResults = ({ addresses, brokers, selectedPrediction, handleClick, handleKeyDown, isVisible }) => {
  const { translate } = useContext(LangContext)

  const results = brokers.concat(addresses).map((d, index) => (
    <LookAheadResult
      key={d.code || d.place_id}
      data={d}
      index={index}
      isSelected={index === selectedPrediction}
      handleClick={handleClick}
      handleKeyDown={handleKeyDown}
    />
  ))

  return (
    <div role="application" id='fab-lookahead' className={`fab-lookahead ${isVisible ? 'open' : ''}`}>
      <div className="is-size-7 fab-lookahead-count" aria-live="polite">{`${brokers.length + addresses.length} ${translate('fab.suggestionsShown')}`}  <span className="ww-visually-hidden">{translate('fab.arrowInstruction')}</span></div>
      {results}
    </div>
  )
}

/**
 * @description Helper component to render individual lines of lookahead results. A separate
 *  component was needed in order to set and toggle focus on individual lines.
 *
 * @param {object} props.data Broker/address data object
 * @param {function} props.index Index of result, used to toggle selection/set focus
 * @param {function} props.isSelected Selection status of current result
 * @param {function} props.handleClick Click event handler
 * @param {function} props.handleKeyDown Key down event handler
 */
const LookAheadResult = ({ data, index, isSelected, handleClick, handleKeyDown }) => {
  const resultRef = useRef()
  const isBroker = Boolean(data.code)


  // Focus is used for styling and accessibility
  useEffect(() => {
    if (isSelected) resultRef.current.focus()
  }, [isSelected])
  
  const getBrokerAddress = (address, city, province) => {
    let tempAddress = (address) ? `${address},` : '';
    let tempCity = (city) ? `${city},` :  '';
    let tempProvince = (province) ? `${province}` : '';
    let brokerAddress =  `${tempAddress} ${tempCity} ${tempProvince}`;
    return brokerAddress;
  }
  
  let result
  if (isBroker) {
    result = (
      <div className="fab-lookahead-item ww-la-broker" data-testid="lookahead-result">
        <a
          href="#"
          onClick={() => handleClick(index)}
          onKeyDown={handleKeyDown}
          ref={resultRef}
        >
          <i className="fa-solid fa-user-tie mr-3 has-text-link" />
          <span>
            {` ${data.name}`}
            <br />
            <span className='is-size-7'>{getBrokerAddress(data.address, data.city, data.province)}</span>
          </span>

        </a>
      </div>
    )
  } else {
    result = (
      <div className="fab-lookahead-item ww-la-address" key={data.place_id}>
        <a
          href="#"
          onClick={() => handleClick(index)}
          onKeyDown={handleKeyDown}
          ref={resultRef}
        >
          <i className="fas fa-map-marker-alt fa-solid fa-location-dot mr-3 has-text-grey" />
          <span>{` ${data.description}`}</span>
        </a>
      </div>
    )
  }

  return result
}

export default LookAheadSearchResults

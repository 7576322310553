import React from 'react'
import Loader from './Loader'

/**
 * @description Helper component to display backup content (or a loader) while waiting for async
 *  code to resolve.
 *
 * @param {*} props.fallback Content to display while waiting async code to resolve
 * @param {boolean} props.isLoading Status of async code that children are dependant on. When
 *  isLoading resolves to false children will display.
 * @param {*} props.children Content to display after async code has resolved
 */
const WaitFor = ({ fallback, isLoading, children }) => (
  isLoading ? (fallback || <Loader fullscreen />) : children
)

export default WaitFor

import React, { useState, useEffect } from 'react'

const useStyleImport = (stylesheetLinks) => {
  const [styles, setStyles] = useState([])
  const [stylesLoaded, setStylesLoaded] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setStyles(stylesheetLinks.map((stylesheet) => {
      let properties = {
        key: stylesheet.href || stylesheet,
        rel: 'stylesheet',
        onLoad: () => setStylesLoaded((prev) => prev + 1),
      }

      if (typeof stylesheet === 'string') {
        properties.href = stylesheet
      } else {
        properties = {
          ...properties,
          ...stylesheet,
        }
      }

      // eslint-disable-next-line react/jsx-key
      return <link {...properties} />
    }))
  }, [stylesheetLinks])

  useEffect(() => {
    if (stylesheetLinks.length === stylesLoaded) setIsLoading(false)
  }, [stylesheetLinks, stylesLoaded])

  return { styles, isLoading }
}

export default useStyleImport

import React, { useEffect, useState, useContext } from 'react'
import { LangContext } from '../../../../localization/localizationContext'
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api'
import logo from '../../../../assets/img/ww-marker.png'
import logoSelected from '../../../../assets/img/ww-marker-selected.png'
import { REACT_APP_GOOGLE_API_KEY, BASE_URL } from '../../../../utils/consts'
import { centreCompare, filterBrokers } from '../../../../utils/mapUtility'
import mapStyle from './mapStyle.json'

const desktopMapHeight = '100%'
const mobileMapHeight = '300px'
const brokerPinCount = 50

const libraries = ['places']

// eslint-disable-next-line max-len
const BrokerMap = ({ center, atAcceptableZoom, markers, zoom, selectedBrokerCode, personalCommercialFilter, lifeGroupBenefitsFilter, handleSelectedPin, handleUpdateBrokers, handleZoomChanged, setMapCenter, setViewBrokersLoaded, setZoom, forceZoomCheck, setForceZoomCheck, mapRef, setBrokerUpdated }) => {
  const { lang } = useContext(LangContext)
  const [map, setMap] = useState()
  // eslint-disable-next-line max-len
  const [mapHeight, setMapHeight] = useState(window.innerWidth <= 725 ? mobileMapHeight : desktopMapHeight)
  const [mapMarkers, setMapMarkers] = useState([])
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    const updateDimensions = () => {
      // Mobile view: update things here...
      if (window.innerWidth <= 725) {
        setMapHeight(mobileMapHeight)
      } else {
        setMapHeight(desktopMapHeight)
      }
    }

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (!isInitialLoad) boundsChanged()
  }, [personalCommercialFilter, lifeGroupBenefitsFilter])

  const adjustZoomLevel = (brokersInView) => {
    if (brokersInView.length < 3 && (isInitialLoad === true || forceZoomCheck === true)) {
      setZoom(map.getZoom() - 1)
    } else if (brokersInView.length === markers.length) {
      setIsInitialLoad(true)
    } else {
      setIsInitialLoad(false)
      setForceZoomCheck(false)
    }
  }

  const boundsChanged = () => {
    setMapCenter(map.getCenter())
    const bounds = map.getBounds()
    const filteredBrokers = filterBrokers(markers, personalCommercialFilter,lifeGroupBenefitsFilter);
    const brokersInView = filteredBrokers.filter((m) => (
      bounds.contains(m.position) 
    )).sort(centreCompare(center))
    // No need to change map zoom when there are no brokers in view
    adjustZoomLevel(brokersInView)
    const newViewBrokers = brokersInView.slice(0, 15)
    handleUpdateBrokers(newViewBrokers)
    setMapMarkers(brokersInView.slice(0, brokerPinCount))
    handleZoomChanged(map.getZoom())
    setViewBrokersLoaded(true)
    if (brokersInView.length >= 3) {
      setBrokerUpdated(true)
    }

  }

  const renderMarker = (m, newLogo, newLogoSelected, clusterer) => {
    const bounds = map.getBounds()
    if (bounds && bounds.contains(m.position)) {
      return (
        <Marker
          key={m.code}
          title={m.name}
          position={m.position}
          icon={m.code === selectedBrokerCode ? newLogoSelected : newLogo}
          clusterer={clusterer}
          onClick={() => handleSelectedPin(m.position, m.code)}
          zIndex={m.code === selectedBrokerCode ? 1 : 0}//keep the selected marker always on top
        />
      )
    }
    return null
  }
  const path = `${BASE_URL}/resources/img/m`
  const options = {
    styles: [1, 2, 3, 4, 5].map((d) => ({
      url: `${path}${d}.png`,
      textColor: 'white',
      height: 55,
      width: 55,
      anchorText: [-3, -3],
    })),
    maxZoom: 14,
  }
  return (
    <div className="fab-search-map" ref={mapRef} tabIndex={0}>
      <LoadScript
        googleMapsApiKey={REACT_APP_GOOGLE_API_KEY}
        language={lang.CODE}
        libraries={libraries}
      >
        <GoogleMap
          options={{
            styles: mapStyle,
          }}
          mapContainerStyle={{
            maxWidth: '700px',
            height: mapHeight,
          }}
          center={center}
          zoom={zoom}
          onIdle={boundsChanged}
          onLoad={setMap}
        >
          {atAcceptableZoom && (
            <MarkerClusterer options={options}>
              {(clusterer) => mapMarkers.map((m) => (
                (renderMarker(m, logo, logoSelected, clusterer))
              ))}
            </MarkerClusterer>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default BrokerMap

import React, { useContext } from 'react'
import { LangContext } from '../../../localization/localizationContext'
import { REACT_APP_SITE } from '../../../utils/consts'

const LayoutLink = ({ attributes, href, children }) => {
  const { lang } = useContext(LangContext)

  const getFullLink = () => {
    if (`${lang.ID}` === '1') {
      if (href.startsWith('/')) {
        return `${REACT_APP_SITE}${href.replace(':lang', lang.CODE.toUpperCase())}`
      } else if (href.startsWith('$')) { 
        let tempHref = href.replace('${host_variable.wawanesaRoot}', '/')
        return  `${REACT_APP_SITE}${tempHref.replace(':lang', lang.CODE.toUpperCase())}`
      }  else if (href.startsWith('#')) {
        return href
      } else {
        return href
      }
    } else {
      if (href.startsWith('/')) {
        return `${REACT_APP_SITE}${href.replace(':lang', lang.CODE.toUpperCase())}?language_id=${lang.ID}`
      } else if (href.startsWith('$')) { 
        let tempHref = href.replace('${host_variable.wawanesaRoot}', '/')
        return  `${REACT_APP_SITE}${tempHref.replace(':lang', lang.CODE.toUpperCase())}?language_id=${lang.ID}`
      }  else if (href.startsWith('#')) {
        return href
      } else {
        return href
      }
    }
  }

  return <a href={getFullLink()} {...attributes}>{children}</a>
}

export default LayoutLink
/* Use back ticks ` in favour of single ' or double "" quotes to have strings evaluated literally in
the case of inline quotes, apostrophes and special characters */

/* eslint-disable quotes */
const fr = {
  wawanesa: `Wawanesa Assurance`,
  language: `Français`,
  otherLanguage: `English`,
  locale: `fr_CA`,
  title: 'Trouver un courtier - Wawanesa Canada',
  common: {
    geo: {
      address: `Adresse`,
      city: `Ville`,
      country: `Pays`,
      postalCode: `Code postal`,
      zipCode: `Code postal`,
      countries: {
        canada: `Canada`,
        usa: `États-Unis`,
      },
      province: `Province`,
      provinces: {
        alberta: `Alberta`,
        britishColumbia: `Colombe-Britannique`,
        manitoba: `Manitoba`,
        newBrunswick: `Nouveau-Brunswick`,
        newfoundlandAndLabrador: `Terre-Neuve-et-Labrador`,
        northwestTerritories: `Territoires du Nord-Ouest`,
        novaScotia: `Nouvelle-Écosse`,
        nunavut: `Nunavut`,
        ontario: `Ontario`,
        princeEdwardIsland: `Île-du-Prince-Édouard`,
        quebec: `Québec`,
        saskatchewan: `Saskatchewan`,
        yukon: `Yukon`,
      },
      state: `État`,
      states: {
        alabama: `Alabama`,
        alaska: `Alaska`,
        arizona: `Arizona`,
        arkansas: `Arkansas`,
        california: `Californie`,
        colorado: `Colorado`,
        connecticut: `Connecticut`,
        delaware: `Delaware`,
        districtOfColumbia: `District of Columbia`,
        florida: `Floride`,
        georgia: `Géorgie`,
        hawaii: `Hawaï`,
        idaho: `Idaho`,
        illinois: `Illinois`,
        indiana: `Indiana`,
        iowa: `Iowa`,
        kansas: `Kansas`,
        kentucky: `Kentucky`,
        louisiana: `Louisiane`,
        maine: `Maine`,
        maryland: `Maryland`,
        massachusetts: `Massachusetts`,
        michigan: `Michigan`,
        minnesota: `Minnesota`,
        mississippi: `Mississippi`,
        missouri: `Missouri`,
        montana: `Montana`,
        nebraska: `Nebraska`,
        nevada: `Nevada`,
        newHampshire: `New Hampshire`,
        newJersey: `New Jersey`,
        newMexico: `Nouveau- Mexique`,
        newYork: `New York (État de)`,
        northCarolina: `Caroline du Nord`,
        northDakota: `Dakota du Nord`,
        ohio: `Ohio`,
        oklahoma: `Oklahoma`,
        oregon: `Oregon`,
        pennsylvania: `Pennsylvanie`,
        rhodeIsland: `Rhode Island`,
        southCarolina: `Caroline du Sud`,
        southDakota: `Dakota du Sud`,
        tennessee: `Tennessee`,
        texas: `Texas`,
        utah: `Utah`,
        vermont: `Vermont`,
        virginia: `Virginie`,
        washington: `Washington (État de)`,
        westVirginia: `Virginie-Occidentale`,
        wisconsin: `Wisconsin`,
        wyoming: `Wyoming`,
      },
    },
    help: `Aide`,
    name: {
      first: `Prénom`,
      last: `Nom`,
    },
    phone: {
      home: `Numéro de téléphone (domocile)`,
      work: `Numéro de téléphone (travail)`,
      cell: `Numéro de téléphone (cellulaire)`,
    },
    email: `Courriel`,
    dateMask: `(MM/JJ/AAAA)`,
    selectOne: `Veuillez choisir`,
    notSelected: `Non séléctionné`,
    unknown: `Inconnu`,
    dob: `Date de naissance`,
    yes: `Oui`,
    no: `Non`,
    prev: `Précédent`,
    next: `Suivant`,
    page: `page`,
    of: `de`,
    edit: `Éditer`,
    printPage: `Imprimer cette page`,
    information: `Information`,
    feedback: `Commentaires`,
  },
  claim: {
    headers: {
      yourInfo: `Vos coordonnées`,
      lossInfo: `Renseignements sur le sinistre`,
      lossLocation: `Lieu du sinistre`,
      otherPartyInfo: `Renseignements sur le tiers`,
      otherPartyVehicleInfo: `Renseignements sur le véhicule du tiers`,
      ourInsuredInfo: `Coordonnées de l'assuré`,
      driverOfInsuredVehicle: `Conducteur du véhicule assuré`,
      ourInsuredVehicleInfo: `Renseignements sur le véhicule que nous assurons`,
      policeReport: `Rapport de police`,
      otherParties: `Tierces parties`,
      passengers: `Passagers`,
      witnesses: `Témoins`,
      mortgagee: `Créancier hypothécaire`,
    },
    intro: `Vous aurez besoin du numéro de votre police d'assurance Wawanesa pour remplir ce formulaire de réclamation.`,
    emergency: {
      text1: `S'il s'agit d'une urgence suite à un sinistre, veuillez appeler au `,
      text2: `. Nous sommes disponibles en tout temps.`,
    },
    requiredField: `signale un champ obligatoire`,
    createClaimReport: {
      home: `Créer un rapport de sinistre - assurance résidentielle`,
      auto: `Créer un rapport de sinistre - assurance automobile`,
    },
    reportingParty: {
      wawanesa: `Assuré chez Wawanesa`,
      broker: `Tiers`,
    },
    phone: {
      instructions: `Veuillez inscrire au moins un numéro de téléphone ci-dessous`,
      ext: `poste`,
    },
    yourInsuranceCompany: `Nom de la compagnie d'assurance`,
    yourInsurancePolicyNum: `Numéro de police d'assurance`,
    lossInformation: `Renseignements sur le sinistre`,
    policyNumber: `Numéro de votre police`,
    dateOfLoss: `Date du sinistre`,
    timeOfLoss: `Heure du sinistre`,
    streetLocation: `Adresse civique ou borne kilométrique de l'autoroute`,
    describeAccident: `Décrivez l'accident ou l'incident`,
    sameAsReportingParty: `Identique à vos coordonnées`,
    sameAsInsured: `Identique à l'adresse de l'assuré`,
    driverOfInsuredVehicleQuestion: `Le conducteur du véhicule assuré correspond-il à la personne indiquée ci-dessus?`,
    noDriver: `Aucun conducteur`,
    driversLicenseNum: `Numéro de permis de conduire`,
    injuries: `Blessures`,
    vehicle: {
      vehicle: `véhicule`,
      year: `Année`,
      make: `Fabricant`,
      model: `Modèle`,
      color: `Couleur`,
      licensePlate: `Numéro d'immatriculation`,
      vin: `NIV`,
      damages: `Dommages au véhicule`,
      vehicleInfo: "Renseignements sur le véhicule",
    },
    reportObtained: `Un rapport de police ou un constat amiable a-t-il été obtenu?`,
    jointReport: `Constat amiable`,
    noReport: `Aucun rapport`,
    policeDeptObtainedFrom: `Service de police duquel le rapport a été obtenu :`,
    policeReportNumber: `Numéro de rapport de police :`,
    howMany: `Si oui, combien`,
    additionalOtherParties: `Y a-t-il d'autres tiers qui ne sont pas indiqués ci-dessus?`,
    anyOtherPassengers: `Y avait-il des passagers dans les véhicules impliqués?`,
    anyWitnesses: `Y avait-il des témoins?`,
    anyMortgagees: `La maison a-t-elle un prêts hypothécaires?`,
    struckByUnknown: `Véhicule que nous assurons frappé par un tiers inconnu`,
    otherParty: `Tiers`,
    passenger: `Passager`,
    passengerLocation: `Localisation du passager dans le véhicule`,
    insuredVehicle: `Véhicule assuré`,
    witness: `Témoin`,
    mortgage: `Prêt hypothécaire`,
    editClaim: `Modifier le formulaire de réclamation`,
    submitClaim: `Soumettre une réclamation`,
    validation: {
      isRequired: `obligatoire`,
      required: `Obligatoire`,
      moreThan: `doit dépasser`,
      lessThan: `ne peut pas dépasser`,
      characters: `caractères`,
      invalid: `Entrée invalide`,
      enterValid: `Entrer un`,
      valid: `valide`,
      futureDate: `La date ne peut pas être ultérieure`,
      invalidDate: `Veuillez saisir une date adéquate, selon le format mm/jj/aaaa`,
      enterPhoneNumber: `Veuillez inscrire au moins un numéro de téléphone ci-dessous`,
    },
    claimSummary: `Sommaire de la réclamation`,
    contactInfo: `Vous pouvez nous joindre en tout temps au`,
    success: {
      submitSuccess: `Réclamation envoyée`,
      thankYou: `Merci d'avoir soumis votre réclamation en ligne. Un membre de notre service des sinistres communiquera avec vous au cours du prochain jour ouvrable.`,
      referenceNumber: `Veuillez conserver le numéro ci-dessous à titre de référence jusqu'à ce qu'un membre du service des sinistres confirme votre numéro de réclamation.`,
      emergency: {
        auto: `En cas d'urgence sinistre (c.-à-d. si vous avez besoin d'un remorquage ou d'une voiture de courtoisie), appelez-nous immédiatement pour obtenir de l'aide.`,
        home: `En cas d'urgence sinistre (c.-à-d. si vous avez subi un dégât d'eau, avez été victime de vol, avez été évacué de votre maison ou avez quelque besoin urgent en lien avec le sinistre que vous avez subi), appelez-nous immédiatement pour obtenir de l'aide.`,
      },
    },
    error: {
      submitError: `Erreur de soumission de réclamation`,
      thankYou: `Merci d'avoir tenté de soumettre votre réclamation en ligne.`,
      technicalDifficulty: `Malheureusement, une difficulté technique nous empêche de traiter le rapport de sinistre que vous avez soumis.`,
      noReSubmit: `S'il-vous-plaît, ne tentez pas d'envoyer de nouveau ce rapport ou de soumettre de nouveau votre réclamation en ligne.`,
      callUs: `Veuillez nous appeler pour effectuer votre réclamation.`,
      apology: `Nous vous prions de nous excuser de tout inconvénient découlant de cette situation.`,
    },
  },
  fab: {
    meta: {
      broker: 'Besoin de trouver un courtier d\'assurance et vous vivez au Canada? Nous sommes là pour vous. Pour l\'auto, la maison, l\'entreprise ou la ferme, communiquez avec l\'un de nos courtiers pour obtenir un devis gratuit dès aujourd\'hui.',
      brokerDirectory: 'Que vous soyez un titulaire de police de Wawanesa ou que vous vouliez obtenir des renseignements sur nos produits, vous trouverez le courtier pour vous aider dans notre répertoire de plus de mille courtiers de Wawanesa Assurance.'
    },
    route: {
      broker: `/courtier`,
      brokerDirectory: `/repertoire-des-courtiers`,
      brokerProfile: `/profil-du-courtier`,
    },
    titleBase: `Wawanesa Assurance`,
    header: `Trouver un courtier`,
    description: 'Filtrez la liste en entrant le nom ou l’adresse du courtier ci-dessous ou ',
    directory: {
      title: `Répertoire des courtiers`,
      backToMap: `Retour à la carte`,
      backToDirectory: `Retour au répertoire`,
      of: 'de',
      filter: {
        instructions: `Entrez un lieu ou le nom d’un cabinet de courtage`,
        text: `Nom ou adresse du courtier`,
      },
      results: `de résultats affichés`,
      profile: `Profil du courtier`,
      noResults: `Désolé, la recherche n''a donné aucun résultat.`,
      previous1: 'Voici la première page',
      previous2: 'Aller à la page précédente',
      next1: 'Voici la dernière page',
      next2: 'Passer à la page suivante',
      currentPage: 'page en cours',
      goToPage: 'Aller à la page',
      skipPage: 'Sauter les pages',
      through: 'à',
    },
    links: {
      profileFor: `Profil de courtier pour`,
    },
    details: {
      enterLocationName: `Entrez un lieu ou le nom d’un cabinet de courtage`,
      fromYourLocation: ` à partir de votre emplacement`,
      filterByInsurance: `Filtrer par type d’assurance:`,
      personalCommercialFilter: `assurance particuliers et entreprises`,
      lifeAndGroupBenefitsFilter: `assurance vie et collective`,
      personalCommercialOffering: `assurance des particuliers et des entreprises`,
      lifeAndGroupBenefitsOffering: `assurance vie et assurance collective`,
      bothPCandLifeOffering: `assurance des particuliers, des entreprises, vie et collective`,
      offers: `Offre:`,
      directions: `Trajet`,
      email: `Courriel`,
      resultsInYourArea: `résultats trouvés dans votre région`,
      pleaseEnterAtLeast3Letters: `Veuillez saisir au moins 3 lettres pour commencer la recherche`,
      call: `Appelez le`
    },
    navigateTo1: `Navigation au`,
    navigateTo2: `Navigation pour`,
    fabUnavailable: `Cette fonctionnalité est temporairement indisponible.`,
    tryAgainLater: `Nous nous excusons de tout inconvénient que cette situation pourrait entraîner. Veuillez réessayer de nouveau plus tard.`,
    noBrokers: `Aucun nom de courtier n'apparaît dans l'affichage`,
    enterNewSearch: `Veuillez entrer un nouvel élément de recherche, sélectionner un filtre de recherche, ou rajuster le niveau de zoom pour réessayer.`,
    refineSearch: `Affinez votre recherche`,
    adjustZoom: `Entrez un emplacement ci-dessus ou faites un zoom avant en utilisant la carte pour trouver un courtier.`,
    fabInstructions: [`Vous désirez obtenir un devis ou avez besoin d’un complément d’information sur la couverture d’assurance? Utilisez cette page pour trouver un courtier près de chez vous ou visitez notre `, `répertoire des courtiers`, ` pour obtenir la liste complète des courtiers.`],
    fabNotice: `Les produits d'assurance-vie et assurance agricole ne sont malheureusement pas disponibles aux résidents du Québec.`,
    suggestionsShown: `de suggestions données`,
    arrowInstruction: `Utilisez les touches fléchées pour parcourir les suggestions.`,
    suggestedResultsInstruction: `Des suggestions de résultats seront fournies au fur et à mesure de la saisie du texte.`,
    currentHighlight: `Actuellement en surbrillance sur la carte`,
    notHighlight: `Cliquez pour mettre en évidence sur la carte`,
  },
  layout: {
    nav: {
      fab: `Trouver un courtier`,
      countrySelect: {
        choose: `Veuillez sélectionner votre région`,
        canada: `Wawanesa Assurance`,
        life: `Wawanesa Vie`,
        california: `Wawanesa California`,
        oregon: `Wawanesa Oregon`,
        home: `Accueil Wawanesa`,
      },
      products: {
        main: `Produits`,
        auto: `Assurance automobile`,
        home: `Assurance habitation`,
        condo: `Assurance habitation pour copropriétaires`,
        tenant: `Assurance locataire`,
        farm: `Assurance agricole`,
        commercial: `Assurance commerciale`,
        life: `Assurance-vie`,
      },
      claims: {
        main: `Réclamations`,
        auto: `Soumettre une réclamation d'assurance auto`,
        home: `Soumettre une réclamation d'assurance habitation`,
        farm: `Soumettre une réclamation d'assurance agricole`,
        info: `Information sur les réclamations`,
      },
      payments: {
        main: `Paiements`,
        makeAPayment: `Effectuer un paiement`,
        options: `Options de paiement`,
        info: `Information sur le paiement et la facturation`,
      },
      about: {
        main: `À propos de nous`,
        mission: 'Notre objectif, notre vision et nos valeurs',
        news: 'Nouvelles',
        info: 'Renseignements sur la société',
        history: 'Histoire de Wawanesa',
        charity: 'Dons de bienfaisance',
        finances: 'Notation financière',
        reports: 'Rapports annuels',
        accessibility: `Accessibilité`,
      },
      contact: {
        main: `Pour nous joindre`,
        general: `Renseignements généraux`,
        jobs: `Emplois`,
        offices: `Succursales et bureaux de service`,
        media: `Demandes des médias`,
        customerCare: `Assistance à la Clientèle`,
        inquiries: `Requêtes et commentaires`,
        faq: `Foire aux questions`,
        concerns: `Plaintes et préoccupations`,
        socialMedia: `Directives pour les médias sociaux`,
      },
      blog: `Blog`,
      search: `Recherche`,
      content: `Passer au contenu principal`,
    },
    footer: {
      discover: `À propos de Wawanesa`,
      aboutUs: `À propos de nous`,
      privacyPolicy: `Politique de confidentialité`,
      contactUs: `Pour nous joindre`,
      copyright1: `Copyright 1996-`,
      copyright2: `Wawanesa Assurance`,
      termsAndConditions: `Conditions générales`,
      brokerPortal: `Plateforme des courtiers`,
      addThisURL: `//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5d6859228326826f`,
      submitFeedbackCTA: `Nous essayons toujours de nous améliorer. Faites-nous part de votre expérience sur notre site Web.`,
      submitFeedback: `Soumettre un commentaire`,
      submitFeedbackURL: `https://feedback.inmoment.com/websurvey/2/begin?gateway=WAWA_DI&SurveyType=5&oc=0000&lang=2&CardType=1&URL=None&UserAgent=None&Device=None&BrowserType=None&BrowserVersion=None`,
    },
  },
}

export default fr
